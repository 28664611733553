<template>
  <sdModal
    centered
    :title="i18n.t(`partners.modal.status_title`)"
    :visible="visible"
    :onCancel="changeModal"
    :width="700"
  >
    <p v-html="i18n.t('partners.modal.status_description')" />
    <TableWrapper class="table-data-view table-responsive">
      <a-table :dataSource="dataSource" class="partner-table" :columns="columns" :pagination="false" ]></a-table>
    </TableWrapper>
  </sdModal>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  setup(props, { emit }) {
    const i18n = reactive(useI18n());
    const dataSource = ref([
      {
        key: 0,
        level: i18n.t('partners.1'),
        reward: '5%',
        turnover: '-',
        client_assets: '-',
        tokens_col: '-',
      },
      {
        key: 1,
        level: i18n.t('partners.2'),
        reward: '6%',
        turnover: '1 000 000 USDT',
        client_assets: '20 000 USDT',
        tokens_col: '-',
      },
      {
        key: 2,
        level: i18n.t('partners.3'),
        reward: '7%',
        turnover: '5 000 000 USDT',
        client_assets: '50 000 USDT',
        tokens_col: '10 000 MTH',
      },
      {
        key: 3,
        level: i18n.t('partners.4'),
        reward: '8%',
        turnover: '10 000 000 USDT',
        client_assets: '100 000 USDT',
        tokens_col: '50 000 MTH',
      },
      {
        key: 4,
        level: i18n.t('partners.5'),
        reward: '9%',
        turnover: '50 000 000 USDT',
        client_assets: '250 000 USDT',
        tokens_col: '100 000 MTH',
      },
      {
        key: 5,
        level: i18n.t('partners.6'),
        reward: '10%',
        turnover: '100 000 000 USDT',
        client_assets: '500 000 USDT',
        tokens_col: '500 000 MTH',
      },
    ]);

    const columns = computed(() => [
      {
        title: i18n.t('partners.modal.level'),
        dataIndex: 'level',
        key: 'level',
      },
      {
        title: i18n.t('partners.modal.reward'),
        dataIndex: 'reward',
        key: 'reward',
      },
      {
        title: i18n.t('partners.modal.turnover'),
        dataIndex: 'turnover',
        key: 'turnover',
      },

      {
        title: i18n.t('partners.modal.client_assets'),
        dataIndex: 'client_assets',
        key: 'client_assets',
      },

      //{
      //  title: i18n.t('partners.modal.tokens_col'),
      //  dataIndex: 'tokens_col',
      //  key: 'tokens_col',
      //},
    ]);

    const changeModal = () => {
      emit('changeModal');
    };

    return { i18n, dataSource, columns, changeModal };
  },
};
</script>

<style></style>
